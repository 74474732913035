// @file Load JS and CSS files dynamically in the browser to use in frontend code
enum LoadState {
  loading,
  loaded,
  error,
}

const loadCache: { [key: string]: LoadState } = {}

async function loadJs(scriptUrl: string, options?: { scriptType?: string }): Promise<string> {
  return await new Promise((resolve, reject): void => {
    if (loadCache[scriptUrl] === LoadState.loaded) {
      resolve(scriptUrl)
    } else if (loadCache[scriptUrl] === LoadState.loading) {
      const scriptElement = document.querySelector(`script[src='${scriptUrl}']`)
      if (scriptElement != null) {
        scriptElement.addEventListener('load', (): void => {
          resolve(scriptUrl)
        })
        scriptElement.addEventListener('error', reject)
      }
    } else {
      loadCache[scriptUrl] = LoadState.loading
      const scriptElement = document.createElement('script')
      scriptElement.type = options?.scriptType != null ? options.scriptType : 'text/javascript'
      scriptElement.addEventListener('load', (): void => {
        loadCache[scriptUrl] = LoadState.loaded
        resolve(scriptUrl)
      })
      scriptElement.addEventListener('error', (e): void => {
        loadCache[scriptUrl] = LoadState.error
        reject(e)
      })
      scriptElement.async = true
      scriptElement.src = scriptUrl
      const anExistingScriptElement = document.getElementsByTagName('script')[0]
      if (anExistingScriptElement.parentNode != null) {
        anExistingScriptElement.parentNode.insertBefore(scriptElement, anExistingScriptElement)
      }
    }
  })
}

async function loadCss(styleUrl: string): Promise<string> {
  return await new Promise((resolve, reject): void => {
    if (loadCache[styleUrl] === LoadState.loaded) {
      resolve(styleUrl)
    } else if (loadCache[styleUrl] === LoadState.loading) {
      const styleElement = document.querySelector(`link[href='${styleUrl}']`)
      if (styleElement != null) {
        styleElement.addEventListener('load', (): void => {
          resolve(styleUrl)
        })
        styleElement.addEventListener('error', reject)
      }
    } else {
      loadCache[styleUrl] = LoadState.loading
      const linkElement: HTMLLinkElement = document.createElement('link')
      linkElement.type = 'text/css'
      linkElement.rel = 'stylesheet'
      linkElement.addEventListener('load', (): void => {
        loadCache[styleUrl] = LoadState.loaded
        resolve(styleUrl)
      })
      linkElement.addEventListener('error', (e): void => {
        loadCache[styleUrl] = LoadState.error
        reject(e)
      })
      linkElement.href = styleUrl
      const anExistingScriptElement = document.getElementsByTagName('script')[0]
      if (anExistingScriptElement.parentNode != null) {
        anExistingScriptElement.parentNode.insertBefore(linkElement, anExistingScriptElement)
      }
    }
  })
}

export { loadCss, loadJs }
